$(document).ready(function() {

/*  Main variables for the entire application
 * ----------------------------------------------------------------------------------------------------------------- */
    var adminFolder = $('body').data('adminfolder');
    var contentFolder = $('body').data('contentfolder');
    var isMobile = false;


/*  Check if we are on a mobile device
 * ----------------------------------------------------------------------------------------------------------------- */
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) isMobile = true;


/*  Loading spinner
 * ----------------------------------------------------------------------------------------------------------------- */
    var opts = {
        lines: 15, // The number of lines to draw
        length: 2, // The length of each line
        width: 2, // The line thickness
        radius: 20, // The radius of the inner circle
        corners: 0, // Corner roundness (0..1)
        rotate: 0, // The rotation offset
        direction: 1, // 1: clockwise, -1: counterclockwise
        color: '#000', // #rgb or #rrggbb or array of colors
        speed: 0.65, // Rounds per second
        trail: 10, // Afterglow percentage
        shadow: false, // Whether to render a shadow
        hwaccel: false, // Whether to use hardware acceleration
        className: 'spinner', // The CSS class to assign to the spinner
        zIndex: 2e9, // The z-index (defaults to 2000000000)
        top: '50%', // Top position relative to parent
        left: '50%' // Left position relative to parent
    };

    var target = document.getElementById('drop-area-spinner');
    new Spinner(opts).spin(target);


/*------------------------------------------------------
    Tooltip
------------------------------------------------------*/
    $('[data-toggle="tooltip"]').tooltip();


/*------------------------------------------------------
    Redactor
------------------------------------------------------*/
    $('.redactor').redactor({
        plugins: ['source'],
        lang: 'fr'
    });

/*------------------------------------------------------
    Datetimepicker
------------------------------------------------------*/
    $('.datetime-picker').datetimepicker({
        format:'Y-m-d H:i:s'
    });


/*------------------------------------------------------
    Visually create the slug of the element
------------------------------------------------------*/
    $('#slug').slugify('#title');


/*------------------------------------------------------
    Switch
------------------------------------------------------*/
    $('input').lc_switch(null, null);

    // triggered each time a field changes status
    $('body').delegate('.lcs_check', 'lcs-statuschange', function() {
        var status = ($(this).is(':checked')) ? 1 : 0;
        var itemid = $(this).data('itemid');
        var ressource = $(this).data('ressource');

        var token = document.getElementById("token").getAttribute('token');

        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (xhttp.readyState == 4 && xhttp.status == 200) {
                console.log('youhou');
            }
        };

        xhttp.open("POST", "updatestatus?status="+status+"&itemid="+itemid+"&ressource="+ressource, true);

        if(token) {
            xhttp.setRequestHeader('X-CSRF-TOKEN', token); // adds directly to the XmlHttpRequest Object
        }

        xhttp.send();
    });



/*------------------------------------------------------
    Dropzone
------------------------------------------------------*/
    $('.drop-zone').livequery(function() {
        $(this).bind({
            dragover: function() {
                $(this).addClass('drag-hover');
            },
            dragleave: function() {
                $(this).removeClass('drag-hover');
            },
        });
    });


/*------------------------------------------------------
    DataTable
------------------------------------------------------*/
    if($("#table").length !== 0) {

        $("#table").DataTable({
            "language": {
                "url": "/js/datatable.french.lang.js",
            },
            "bLengthChange": false,
            "bJQueryUI": false,
            "responsive": {
                "details": false
            },
            "bAutoWidth": false,
            "iDisplayLength": 50,
            "sPaginationType": "full_numbers",
            "oTags": {
                "container": "div",
                "button": "a",
                "liner": "span",
                "collection": {
                    "container": "div",
                    "button": "a",
                    "liner": "span"
                }
            },
            "oLanguage": {
                "sSearch": "",
            },
            "aaSorting": [[ 0, "desc" ]],
            "sDom": "<'data-table-header'<'dataTables_header clear'<'left'l><'right table-actions-btn'f>r>>t<'data-table-footer'<'dataTables_footer clear'<'left'i><'right'p>>>",
        });
    }


/*------------------------------------------------------
    Tabs
------------------------------------------------------*/
    $('a[data-toggle="tab"]').on('show.bs.tab', function(e) {
        localStorage.setItem('activeTab', $(e.target).attr('href'));
    });

    var activeTab = localStorage.getItem('activeTab');
    
    if(activeTab) {
        $('#myTab a[href="' + activeTab + '"]').tab('show');
    }
});